<template>
  <section id="portfolio-transactions">
    <b-overlay :show="showOverlay">
      <b-card no-body>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="8"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Количество записей</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1 select-size-sm"
              />
            </b-col>

            <!-- Filters -->
            <b-col cols="12" md="4">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                  size="sm"
                />
                <v-select
                  v-model="confirmedFilter"
                  :options="confirmedOptions"
                  :reduce="(status) => status.value"
                  :searchable="false"
                  class="transactions-filter-select select-size-sm"
                  placeholder="Статус"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          class="position-relative"
          ref="refTransactionsTable"
          :items="fetchTransactions"
          responsive
          :fields="fields"
          primary-key="tid"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Сделки отсутствуют"
          :sort-desc.sync="isSortDirDesc"
          small
        >
          <template #cell(confirmed)="data">
            <feather-icon :icon="data.value ? '' : 'AlertTriangleIcon'" class="text-danger" />
          </template>

          <template #cell(action)="data">
            <b-dropdown variant="link" no-caret size="sm">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="editTransaction(data.item.id)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Изменить</span>
              </b-dropdown-item>

              <b-dropdown-item @click="confirmDeleteTransaction(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Показано с {{ dataMeta.from }} по {{ dataMeta.to }} из
                {{ dataMeta.of }} записей</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import vSelect from "vue-select";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { debouncedWatch } from "@vueuse/core";
import Ripple from "vue-ripple-directive";

import { ref, watch, computed } from "@vue/composition-api";

import usePortfolio from "@/comp-functions/usePortfolio";
import store from "@/store";

import { asCurrency, asDecimal, asIntenger, asDate } from "@/utils/formatter";

export default {
  name: "Transactions",
  components: {
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup(props, context) {
    const toast = useToast();
    const showOverlay = ref(false);
    const { currentPortfolio, updateData } = usePortfolio();

    const editTransaction = (id) => {
      store.commit("investments/SET_TRANSACTION", id);
      context.root.$bvModal.show("modal-transaction");
    };
    const confirmDeleteTransaction = (id) => {
      context.root.$bvModal
        .msgBoxConfirm("Вы действительно хотите удалить сделку?", {
          cancelVariant: "outline-secondary",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Да",
          cancelTitle: "Нет",
        })
        .then((value) => {
          if (value) {
            store.dispatch("investments/deleteTransaction", id).then(() => {
              store.commit("investments/SET_UPDATE_PORTFOLIO_DATA");
              toast({
                component: ToastificationContent,
                props: {
                  title: "Сделка удалена",
                  icon: "ThumbsUpIcon",
                  variant: "success",
                },
              });
            });
          }
        });
    };

    // Transactions data
    const perPage = ref(15);
    const totalRows = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [15, 25, 50];
    const searchQuery = ref(null);
    const sortBy = ref("date");
    const isSortDirDesc = ref(true);
    const confirmedFilter = ref(null);

    const confirmedOptions = [
      { label: "Подтвержденые", value: true },
      { label: "Неподтвержденые", value: false },
    ];

    const refTransactionsTable = ref(null);
    const fetchTransactions = async () => {
      showOverlay.value = true;
      try {
        const params = {
          portfolio_id: currentPortfolio.value.id,
          limit: perPage.value,
          skip: (currentPage.value - 1) * perPage.value,
          q: searchQuery.value,
          status: confirmedFilter.value,
          ordering: isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value,
        };
        const results = await store.dispatch("investments/getTransactions", params);
        totalRows.value = results.total;
        return results.transactions;
      } catch (err) {
        console.error(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "Ошибка загрузки данных",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: "Перезагрузите страницу",
          },
        });
        throw err;
      } finally {
        showOverlay.value = false;
      }
    };

    // Table
    const fields = [
      {
        key: "confirmed",
        label: "",
      },
      {
        key: "date",
        label: "Дата",
        sortable: true,
        formatter: (val) => asDate(val),
      },
      {
        key: "securityName",
        label: "Название",
        formatter: (val) => val || "Деньги",
      },
      { key: "securityCode", label: "Тикер" },
      { key: "operationName", label: "Операция" },
      {
        key: "quantity",
        label: "Количество",
        formatter: (val) => asIntenger(val),
      },
      {
        key: "price",
        label: "Цена",
        formatter: (val) => asDecimal(val, 2, 6),
      },
      {
        key: "faceValue",
        label: "Номинал",
        formatter: (val) => asIntenger(val),
      },
      {
        key: "nkd",
        label: "НКД",
        formatter: (val) => asDecimal(val),
      },
      {
        key: "commission",
        label: "Комиссия",
        formatter: (val) => asDecimal(val),
      },
      {
        key: "tax",
        label: "Налог",
        formatter: (val) => asDecimal(val),
      },
      {
        key: "total",
        label: "Сумма",
        sortable: true,
        // formatter: (val, key, item) => currencyFormatter(parseFloat(val), item.currencyCode),
        formatter: (val, key, item) => asCurrency(val, item.currencyCode),
      },
      { key: "note", label: "Примечание" },
      {
        key: "action",
        label: "",
      },
    ];

    const refetchData = () => {
      refTransactionsTable.value.refresh();
    };

    watch([currentPage], () => {
      refetchData();
    });
    watch([currentPortfolio, confirmedFilter, perPage, updateData], () => {
      currentPage.value = 1;
      refetchData();
    });
    debouncedWatch(searchQuery, () => refetchData(), { debounce: 700 });

    const dataMeta = computed(() => {
      const localItemsCount = refTransactionsTable.value
        ? refTransactionsTable.value.localItems.length
        : 0;
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRows.value,
      };
    });

    return {
      showOverlay,
      fields,
      refTransactionsTable,
      fetchTransactions,

      perPage,
      perPageOptions,
      currentPage,
      totalRows,
      searchQuery,
      sortBy,
      isSortDirDesc,
      confirmedFilter,
      confirmedOptions,
      dataMeta,

      editTransaction,
      confirmDeleteTransaction,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.transactions-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
// @import '@core/scss/vue/libs/vue-select.scss';
</style>
